// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-celebration-cakes-js": () => import("./../../../src/pages/celebrationCakes.js" /* webpackChunkName: "component---src-pages-celebration-cakes-js" */),
  "component---src-pages-enquiries-js": () => import("./../../../src/pages/enquiries.js" /* webpackChunkName: "component---src-pages-enquiries-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tandc-js": () => import("./../../../src/pages/tandc.js" /* webpackChunkName: "component---src-pages-tandc-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

